import React from 'react'
import { Container, Row, Col } from 'reactstrap'

export default function Actividades() {
  return (
    <div className="features-1" id="actividades">
        <Container>
            <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">Actividades</h2>
                    <h5 className="description">
                        La ubicación privilegiada de "Reserva Los Muermos" te ofrece una amplia 
                        variedad de actividades y lugares de interés cercanos para disfrutar 
                        durante tu estadía.
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <div className="info">
                        <div className="icon icon-danger">
                            <i className="nc-icon icon-watering-plants" />
                        </div>
                        <div className="description">
                            <h4 className="info-title">Explora la flora y fauna</h4>
                            <p className="description">
                            Disfruta de caminatas y trekking rodeado de una belleza natural impresionante. 
                            Observa aves y descubre la fauna local en su hábitat natural.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md="4">
                    <div className="info">
                        <div className="icon icon-danger">
                            <i className="nc-icon icon-beach-umbrella" />
                        </div>
                        <div className="description">
                            <h4 className="info-title">Playa Estaquilla</h4>
                            <p className="text-left">
                            A pocos minutos de "Reserva Los Muermos" se encuentra la playa Estaquilla, 
                            donde podrás relajarte, disfrutar del sol y las vistas panorámicas del 
                            océano Pacífico.
                            </p>
                        </div>
                    </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon icon-store" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Gastronomía local</h4>
                    <p className="text-left">
                      Descubre la riqueza gastronómica de la región de Los Lagos. 
                      Disfruta de los sabores auténticos de los mariscos y pescados 
                      frescos en los restaurantes locales. Deleita tu paladar con platos 
                      tradicionales preparados por chefs talentosos.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon icon-rowing" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Ríos y lagos</h4>
                    <p className="text-left">
                      Aprovecha la cercanía de ríos y lagos para disfrutar de actividades acuáticas 
                      como la pesca deportiva, kayak y paseos en bote. Sumérgete en la belleza 
                      natural de los paisajes acuáticos y disfruta de momentos de tranquilidad 
                      rodeado de naturaleza.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon icon-skiing" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Deportes de aventura </h4>
                    <p className="text-left">
                      Si buscas emociones fuertes, no te pierdas la oportunidad de practicar 
                      deportes de aventura. Descender los rápidos en emocionantes sesiones de 
                      rafting o disfrutar de la adrenalina del kayak en los ríos de la zona. 
                      Si gustas de los deportes de invierno no dudes en visitar los centros 
                      de Ski que los alrededores ofrecen!
                    </p>
                    
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <p className="description text-left">
                En "Reserva Los Muermos", tendrás la posibilidad de disfrutar de una amplia gama de actividades 
                al aire libre y explorar los encantos naturales de la región. 
                ¡Ven y descubre tu oasis de tranquilidad y aventura en la Región de Los Lagos!
              </p>
            </Row>
          </Container>
        </div>
  )
}
