import React from "react";
import { Container, Row, Col } from "reactstrap";

  function Proyecto(){
    return (
      <>
        <div className="section section-feature cd-section">
          <div className="features-1" id="proyecto"></div>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto left pb-5" md="8">
                <h2 className="title">Reserva Los Muermos</h2>
                <h5 className="description">
                  Bienvenidos a "Reserva Los Muermos" en la Región de Los Lagos, Chile. 
                  Nuestro proyecto ofrece una experiencia única que combina la belleza 
                  natural de la flora y fauna que ofrece esta hermosa  ubicación privilegiada cerca de Puerto Montt y la playa estaquilla. 
                </h5>
                <h5 className="description">
                  Sumérgete en un oasis de tranquilidad con nuestros 41 lotes cuidadosamente diseñados, 
                  cada uno con rol propio, delimitados con estacas , caminos y entrada independiente 
                  para garantizar la privacidad. Nuestra ubicación estratégica a tan solo 40 min 
                  de Puerto Montt  te permitirá disfrutar de la serenidad de la naturaleza y la 
                  proximidad a las atracciones turísticas de la zona.
                </h5>
                <h5 className="description">
                  Maravíllate con la exuberante flora y fauna que caracteriza la zona. Adéntrate en 
                  bosques nativos y contempla la diversidad de especies de árboles. 
                  En "Reserva Los Muermos", tendrás la oportunidad de vivir en armonía con la naturaleza 
                  y disfrutar de paisajes inolvidables.
                </h5>
              </Col>
            </Row>
          </Container> 
        </div>
      </>
    );
}

export default Proyecto;
