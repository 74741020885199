import React from "react";
import { Container, Row, Col } from "reactstrap";
import Map from './map'

function Ubicacion(props) {
  return (
    <>
      <div className="section section-feature cd-section" id="ubicacion">
        <div className="features-4">
        <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Ubicación</h2>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Map/>
          </Col>
          <Col className="offset-1" md="4">
            <div className="info info-horizontal pt-0 pt-sm-4">
              <div className="icon icon-info">
                <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
              </div>
              <div className="description mr-0">
                <h4 className="info-title">Distancias</h4>
                <ul className="pl-0">
                  <li>A 10 min de Los Muermos.</li>
                  <li>A 20 min del aeropuerto.</li>
                  <li>A 40 min de Puerto Montt.</li>
                  <li>45 min de Puerto Varas.</li>
                  <li>50 min del cruce Chiloé-Pargua..</li>
                  <li>1 hora del Lago LLanquihue, Volcán Osorno y Saltos del Petrohué.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
        </div>
      </div>
    </>
  );
}

export default Ubicacion;
